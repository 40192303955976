.add-button {
  background-color: rgb(3, 52, 212);
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
}

.add-button:hover {
  background-color: rgb(0, 60, 255);
}

@media screen and (max-width: 768px) {
  .add-button {
    width: 70px;
    font-size: 10px;
  }
}
