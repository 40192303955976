.add-task-container {
  margin: 15px 0;
  width: 100%;
  display: flex;
}

.add-task-input {
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  border: none;
  flex: 2;
  background-color: #444;
  color: #eee;
  font-size: 16px;
}

.add-task-button-container {
  margin-left: 10px;
  flex: 1;
}

@media screen and (max-width: 768px) {
  .add-task-input {
    width: 50px;
  }
}
