.task-container {
  background-color: #444;
  margin: 8px 0;
  padding: 15px 20px;
  display: flex;
  border-radius: 5px;
  justify-content: space-between;
  color: #eee;
  align-items: center;
}

.task-title {
  cursor: pointer;
}

.remove-task-button,
.info-task-button {
  background-color: #444;
  border: none;
  font-size: 24px;
  color: #eee;
  cursor: pointer;
  margin: 0 5px;
}

@media screen and (max-width: 768px) {
  .task-container {
    width: 150px;
  }
}
