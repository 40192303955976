.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 2px solid rgb(0, 60, 255);
  padding: 30px;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 200px;
  }
}
